.p-card-title,
.p-card-subtitle {
  text-align: center;
}

.p-progressbar {
  @apply bg-progress-bar-bg dark:bg-dark-progress-bar-bg #{!important};
  height: 0.5rem;
  border-radius: 5px;

  .p-progressbar-value {
    @apply bg-progress-bar dark:bg-dark-progress-bar;
  }
}

.p-tag {
  @apply bg-progress-bar dark:bg-dark-progress-bar #{!important};
  @apply text-dark-text-color dark:text-text-color #{!important};
}

p-scrolltop > button.p-scrolltop {
  z-index: 1004;
}

p-panel {

  .p-panel-header {
    display: grid;
    justify-content: center;
    @apply border;
  }

  .p-panel-content {
    @apply border-b border-r border-l;
  }

  .p-panel-header,
  .p-panel-content {
    @apply bg-panel-title-bg dark:bg-dark-panel-title-bg #{!important};
    @apply text-text-color dark:text-dark-text-color #{!important};
    @apply border-panel-border dark:border-dark-panel-border #{!important};
  }
}

p-card {
  > div.p-card {
    @apply bg-card-bg dark:bg-dark-card-bg #{!important};
    @apply text-text-color dark:text-dark-text-color #{!important};
    position: sticky;
    top: 2rem;
  }

  &.alt-card > div.p-card {
    @apply bg-card-bg dark:bg-dark-body-bg #{!important};
  }
}

.p-menu {
  ul {
    @apply list-none;
    li {
      @apply ml-0;
      @apply mt-0;
      @apply mb-0;
    }
  }

  &.p-menu-overlay {
    @apply bg-menu-bg dark:bg-dark-menu-bg #{!important};
  }

  .p-submenu-header {
    display: flex;
    justify-content: center;
    @apply bg-menu-bg dark:bg-dark-menu-bg #{!important};
  }

  .p-menuitem-link .p-menuitem-text,
  .p-submenu-header {
    @apply text-text-color dark:text-dark-text-color #{!important};
  }

  .p-menuitem-icon {
    transition: color 0s, background-color 0s;
  }

  .p-menuitem-link:not(.p-disabled):hover {
    @apply bg-menu-item-hover dark:bg-dark-menu-item-hover #{!important};
    .p-menuitem-text,
    .p-menuitem-icon {
      transition: color 0s, background-color 0s;
      @apply text-text-color dark:text-dark-text-color #{!important};
    }
  }
}

button.p-button {
  > span {
    @apply text-base;
  }

  @apply text-menu-button dark:text-dark-menu-button #{!important};

  &.p-button.p-button-text,
  &.p-button.p-button-text:enabled:hover,
  &.p-button.p-button-text:enabled:active {
    @apply text-menu-button dark:text-dark-menu-button #{!important};
  }

  &.p-button.p-button-text:enabled:hover,
  &.p-button.p-button-text:enabled:active {
    background: rgba(75, 85, 94, 0.04);
  }

  &.p-button:focus,
  &.p-button.p-button-text:enabled:active {
    box-shadow: 0 0 0 0.2rem #aab1b9;
  }
}

p-chip[label='gilles.gardet@gmail.com'] {
  --params: 0.8s ease;

  .p-chip {
    position: relative;
    overflow: hidden;
    transition: color var(--params), background-color var(--params);
    z-index: 0;
    @apply bg-chip-bg dark:bg-dark-chip-bg #{!important};
    @apply text-dark-text-color dark:text-text-color #{!important};

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      @apply bg-dark-chip-bg dark:bg-chip-bg #{!important};
      transform: translateX(100%);
      transition: transform var(--params), opacity var(--params);
    }

    &:hover {
      cursor: pointer;
      @apply text-text-color dark:text-dark-text-color #{!important};
      background-color: transparent;

      &::before {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
}

.p-timeline-event-content,
.p-timeline-event-opposite {
  line-height: 1;
}

.p-button.p-button-secondary.p-button-outlined {
  color: var(--dark);
}

.mission {
  box-shadow: none;

  &__animation-left {
    opacity: 0;
    transform: translateX(-40px);
    animation: fadeIn 3s forwards;
    -webkit-animation: fadeIn 3s forwards;
    -moz-animation: fadeIn 3s forwards;
    -o-animation: fadeIn 3s forwards;
    -ms-animation: fadeIn 3s forwards;
  }

  &__animation-right {
    opacity: 0;
    transform: translateX(40px);
    animation: fadeIn 3s forwards;
    -webkit-animation: fadeIn 3s forwards;
    -moz-animation: fadeIn 3s forwards;
    -o-animation: fadeIn 3s forwards;
    -ms-animation: fadeIn 3s forwards;
  }

  &__button {
    display: flex;
    justify-content: center;
  }
}

.p-timeline-event-content > p-card > .p-card > .p-card-body > .p-card-content {
  text-align: left;
}

.p-timeline .p-timeline-event-connector {
  @apply bg-timeline-connector dark:bg-dark-timeline-connector #{!important};
}

.p-card-content {
  display: grid;
  gap: 1rem;
}

.p-dialog {
  .p-dialog-header,
  .p-dialog-content {
    @apply bg-card-bg dark:bg-dark-card-bg #{!important};
    @apply text-text-color dark:text-dark-text-color #{!important};
  }

  .p-dialog-header {
    display: grid;

    .p-dialog-header-icons {
      grid-area: 1 / 3 / 1 / 4;
      align-self: flex-start;

      > button:hover {
        @apply bg-dark-card-bg dark:bg-card-bg #{!important};
        @apply text-dark-text-color dark:text-text-color #{!important};
      }
    }
  }

  .p-dialog-content {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: hidden;

    .p-progress-spinner-circle {
      animation: progress-spinner-dash 1.5s ease-in-out infinite, progress-spinner-color 6s ease-in-out infinite;
    }

    @keyframes progress-spinner-color {
      100%,
      0%,
      40%,
      66%,
      80%,
      90% {
        @apply stroke-text-color dark:stroke-dark-text-color #{!important};
      }
    }

    @keyframes progress-spinner-dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
      }
      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
      }
    }
  }

  .p-dialog-content-scroll {
    overflow-y: auto;
    animation: hideScroll 800ms backwards;
  }
}

.p-scrolltop {
  &.p-link {
    @apply bg-scroll-bg dark:bg-dark-scroll-bg #{!important};
  }

  .p-scrolltop-icon {
    @apply text-dark-text-color dark:text-text-color #{!important};
    @apply w-4 h-4;
  }
}

.p-link:focus {
  box-shadow: 0 0 0 0.2rem #aab1b9;
}


@keyframes hideScroll {
  from,
  to {
    overflow-y: hidden;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  to {
    transform: translateX(0);
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  to {
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  to {
    transform: translateY(0);
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  to {
    transform: translateX(0);
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  to {
    transform: translateX(0);
  }
}

@media screen and (max-width: 960px) {
  .customized-timeline {
    .p-timeline-event:nth-child(even) {
      flex-direction: row !important;

      .p-timeline-event-content {
        text-align: left !important;
      }
    }

    .p-timeline-event-opposite {
      display: none;
    }

    .p-card {
      margin-top: 1rem;
    }
  }
}
