:root {
  --button: hsl(210, 9%, 31%);
  --slide-button-bg: hsl(210, 17%, 98%);
  --code-text: hsl(203, 19%, 90%);
  --chip-bg: hsl(216, 14%, 89%);
  --body-bg: hsl(0deg 0% 92%);
  --panel-title-bg: hsl(210, 17%, 98%);
  --panel-content-bg: hsl(0, 0%, 100%);
  --typewriter-bg: hsl(49 37% 94%);
  --white: hsl(110, 100%, 100%);
  --dark-grey: hsl(210, 9%, 31%);
  --dark: hsl(0, 0%, 20%);
  --black: hsl(0 0% 0% / 0.7);
  // dark-mode
  --dark-body-bg: hsl(214, 18%, 15%);
  --dark-panel-title-bg: hsl(217, 17%, 20%);
  --dark-panel-content-bg: hsl(217, 17%, 20%);
}
