*,
*::before,
*::after {
  box-sizing: inherit;
  transition: color 0.15s, background-color 0.5s;
}

html {
  font-size: 100%;
  box-sizing: border-box;

  &.dark {
    body {
      background: var(--dark-body-bg);
    }
  }

  body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    display: grid;
    place-content: center;
    text-align: center;
    background: var(--body-bg);
    scroll-behavior: smooth;
    scrollbar-gutter: stable;
    overflow-y: auto;

    p,
    li {
      @apply mt-2;
      @apply mb-2;
      @apply leading-5;
    }

    ul {
      @apply list-disc;
      li {
        @apply ml-8;
      }
    }
  }
}
