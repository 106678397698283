@use 'sass:string';

$base_size: 0.25rem;
$sizes: (
  'size-1': $base_size * 1,
  'size-2': $base_size * 2,
  'size-3': $base_size * 3,
  'size-4': $base_size * 4,
  'size-5': $base_size * 5,
  'size-6': $base_size * 6,
  'size-7': $base_size * 7,
  'size-8': $base_size * 8,
);
$sides: top, bottom, right, left;

@each $size-name, $size_value in $sizes {
  $size: string.slice($size-name, 6);
  .m {
    @each $side_value in $sides {
      $side: string.slice($side_value, 0, 1);
      &#{$side}-#{$size} {
        margin-#{$side_value}: $size_value;
      }
      &-#{$size} {
        margin: $size_value;
      }
    }
  }
  .p {
    @each $side_value in $sides {
      $side: string.slice($side_value, 0, 1);
      &#{$side}-#{$size} {
        padding-#{$side}: $size_value;
      }
      &-#{$side_value} {
        padding: $size_value;
      }
    }
  }
}
