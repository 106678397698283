@media print {
  body {
    overflow: visible;
    background: white;
  }

  button {
    display: none !important;
  }

  #container > aside {
    padding-top: 2rem !important;
    padding-bottom: 0 !important;
  }

  .pagebreak-avoid {
    page-break-after: avoid;
    page-break-before: avoid;
  }

  .pagebreak-after {
    page-break-after: always;
  }

  .pagebreak-before {
    page-break-before: always;
  }

  cv-missions > cv-panel > div,
  cv-hobbies > cv-panel > div {
    page-break-before: always;
    padding-top: 2rem;
  }

  cv-missions > cv-panel {
    .panel__header {
      display: none;
    }

    .panel .panel__content {
      border: unset;
    }

    .mission__title {
      color: hsl(214, 18%, 15%);
    }
  }

  .customized-timeline {
    &.p-timeline {
      display: flex;
      gap: 2rem;
      align-content: space-evenly;
    }

    .p-timeline-event {
      page-break-inside: avoid;
      page-break-after: auto;
      page-break-before: auto;
    }

    .p-timeline-event-separator {
      display: none;
    }

    .p-timeline-event-opposite {
      flex: 0;
      padding: 0 !important;
    }

    .p-timeline-event:nth-child(even) {
      flex-direction: row !important;
    }
  }
}

@page {
  margin: auto;
}
