.p-menu {
  ul {
    @apply list-none;
    li {
      @apply ml-0;
      @apply mt-0;
      @apply mb-0;
    }
  }

  &.p-menu-overlay {
    @apply bg-menu-bg dark:bg-dark-menu-bg #{!important};
  }

  .p-submenu-header {
    display: flex;
    justify-content: center;
    @apply bg-menu-bg dark:bg-dark-menu-bg #{!important};
  }

  .p-menuitem-link .p-menuitem-text,
  .p-submenu-header {
    @apply text-text-color dark:text-dark-text-color #{!important};
  }

  .p-menuitem-icon {
    transition:
      color 0s,
      background-color 0s;
  }

  .p-menuitem-link:not(.p-disabled):hover {
    @apply bg-menu-item-hover dark:bg-dark-menu-item-hover #{!important};
    .p-menuitem-text,
    .p-menuitem-icon {
      transition:
        color 0s,
        background-color 0s;
      @apply text-text-color dark:text-dark-text-color #{!important};
    }
  }
}

.p-dialog {
  .p-dialog-header,
  .p-dialog-content {
    @apply bg-card-bg dark:bg-dark-card-bg #{!important};
    @apply text-text-color dark:text-dark-text-color #{!important};
  }

  .p-dialog-header {
    display: grid;

    .p-dialog-header-icons {
      grid-area: 1 / 3 / 1 / 4;
      align-self: flex-start;

      > button:hover {
        @apply bg-dark-card-bg dark:bg-card-bg #{!important};
        @apply text-dark-text-color dark:text-text-color #{!important};
      }
    }
  }

  .p-dialog-content {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: hidden;

    .p-progress-spinner-circle {
      animation:
        progress-spinner-dash 1.5s ease-in-out infinite,
        progress-spinner-color 6s ease-in-out infinite;
    }

    @keyframes progress-spinner-color {
      100%,
      0%,
      40%,
      66%,
      80%,
      90% {
        @apply stroke-text-color dark:stroke-dark-text-color #{!important};
      }
    }

    @keyframes progress-spinner-dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
      }
      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
      }
    }
  }

  .p-dialog-content-scroll {
    overflow-y: auto;
    animation: hideScroll 800ms backwards;
  }
}

@keyframes hideScroll {
  from,
  to {
    overflow-y: hidden;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  to {
    transform: translateX(0);
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  to {
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  to {
    transform: translateY(0);
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  to {
    transform: translateX(0);
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  to {
    transform: translateX(0);
  }
}
